<template>
  <div class="goods">
    <van-sticky :offset-top="0">
      <demo-block :title="('')">
        <van-nav-bar :title="('商品信息')" :left-text="('返回')" left-arrow @click-left="this.$router.push('shop')">
          <template #right>
            <van-icon name="search" size="18" />
          </template>
        </van-nav-bar>
      </demo-block>
    </van-sticky>
    <van-swipe class="goods-swipe" :autoplay="3000">
      <van-swipe-item v-for="thumb in goods.thumb" :key="thumb">
        <img :src="thumb" />
      </van-swipe-item>
    </van-swipe>

    <van-cell-group>
      <van-cell>
        <div class="goods-title">{{ goods.title }}</div>
        <div class="goods-price">{{ formatPrice(goods.price) }}</div>
      </van-cell>
      <van-cell class="goods-express">
        <van-col span="10">运费：{{ goods.express }}</van-col>
        <van-col span="14">剩余：{{ goods.remain }}</van-col>
      </van-cell>
    </van-cell-group>
    <van-cell-group class="goods-cell-group">
      <van-cell title="查看商品详情" is-link @click="sorry" />
    </van-cell-group>

    <van-action-bar>
      <van-action-bar-icon icon="cart-o" @click="onClickCart">
        购物车
      </van-action-bar-icon>
      <van-action-bar-button type="warning" @click="ClickToCart">
        加入购物车
      </van-action-bar-button>
      <van-action-bar-button type="danger" @click="ClickBuy">
        立即购买
      </van-action-bar-button>
    </van-action-bar>
  </div>
</template>

<script>
import {
  Tag,
  Col,
  Icon,
  Cell,
  CellGroup,
  Swipe,
  Toast,
  SwipeItem,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  NavBar,
  Sticky
} from 'vant';

export default {
  components: {
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [ActionBar.name]: ActionBar,
    [ActionBarIcon.name]: ActionBarIcon,
    [ActionBarButton.name]: ActionBarButton,
    [NavBar.name]: NavBar,
    [Sticky.name]: Sticky,
  },

  data() {
    return {
      goods: {
        title: '美国伽力果（约680g/3个）',
        price: 2680,
        express: '免运费',
        remain: 19,
        thumb: [
          'https://img.yzcdn.cn/public_files/2017/10/24/e5a5a02309a41f9f5def56684808d9ae.jpeg',
          'https://img.yzcdn.cn/public_files/2017/10/24/1791ba14088f9c2be8c610d0a6cc0f93.jpeg',
        ],
      },
    };
  },
  watch: {},
  mounted() {
    console.log('goods  mounted .......')
    console.log(this.$route.query);
    if (this.$route.query.id === undefined || this.$route.query.id === null) {
      console.log('都为空，所以直接返回')
      this.$router.push({ path: 'shop?' + new Date() });
      return;
    }
    const id = this.$route.query.id;
    // const time = this.$route.query.time;
    // console.log(time)
    this.goods = {}
    // console.log('获得的id=' + id)
    // console.log(this.$store.state.goodslist)
    this.$store.state.goodslist.forEach(info => {
      // console.log(info.id)
      if (info.id.toString() === id.toString()) {
        console.log('有设备信息，找到了')
        this.goods = info;
        return;
      }
    });
    console.log(this.goods)
    if (this.goods.id ===undefined) {
      Toast('此商品不存在');
      this.$router.push({ path: 'shop?' + new Date() });
      return;
    }
    // console.log('找到商品嘻嘻如下：' , this.goods)


  },
  methods: {
    ClickToCart() {
      var isFind = false;
      // if (this.goods.title === '') this.goods.title = this.goods.goodinfo.substr(1, this.goods.goodinfo.IndexOf(" "));
      console.log(this.goods.goodinfo)
      // var s = this.goods.goodinfo
      console.log(this.goods.goodinfo.substr(1, this.goods.goodinfo.IndexOf(" ")))
      console.log(this.goods)

      Toast("加入购物车")
      this.$store.state.cartlist.forEach(g => {
        if (g.id === this.goods.id) {
          g.num = g.num + 1;
          isFind = true;
          return;
        }
      });
      if (isFind === false) this.$store.state.cartlist.push(this.goods)
      console.log(this.$store.state.cartlist)
      Toast("加入购物车成功")
    },
    ClickBuy() {
      Toast('加入购物车，然后跳转到结账')
      this.ClickToCart();
      this.$router.push({ path: 'order?' + new Date() });
    },
    formatPrice() {
      console.log(this.goods)
      return '¥' + (this.goods.price / 1).toFixed(2);
    },

    onClickCart() {
      this.$router.push({ path: 'cart?' + new Date() });
    },

    sorry() {
      Toast('暂无后续逻辑~');
    },
  },
};
</script>

<style lang="less">
.goods {
  padding-bottom: 50px;

  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }

  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;

    .van-cell__value {
      color: #999;
    }
  }

  &-tag {
    margin-left: 5px;
  }
}
</style>
